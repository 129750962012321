<template>
  <div>
    <el-row>
      <el-col :span="12">
         <h4>Behandlungskonzept / Behandlungsplan</h4>
      </el-col>
      <el-col :span="12" class="text-right">
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <h6>Diagnose</h6>
        <el-select size="medium" class="w-100" v-model="form.diagnosis" filterable>
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
              v-for="item in allDiagnosis"
              :key="item.id"
              :label="(item.code + ', ' + item.title)"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="6">
        <h6>Behandlungssituation / Therapiemodalität</h6>
        <el-select size="medium" class="w-100" v-model="form.treatmentsituation" filterable>
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
              v-for="item in allTreatmentSituations"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :offset="1" :span="6">
        <h6>Biomarkermuster</h6>
        <el-select size="medium" class="w-100" v-model="form.biomarker" filterable>
          <el-option :value="null" label="-- Keine Auswahl --"></el-option>
          <el-option
              v-for="item in allBiomarker"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <hr/>

    <h5>Behandlungskonzept auswählen</h5>

    <el-row>
      <el-col :span="6" class="round">

        <el-table
            :data="filteredTherapyConcepts"
            style="width: 100%"
            :show-header="false"
            highlight-current-row
            @row-click="onTherapyConceptRowClick"
            height="500"
            stripe>
          <el-table-column>
            <template #default="scope">
              {{ scope.row.title }}
            </template>
          </el-table-column>
        </el-table>

      </el-col>
      <el-col :span="18" v-if="!currentTherapyConcept" v-loading="currentTherapyConceptLoading">
      </el-col>
      <el-col :span="18" v-if="currentTherapyConcept" >

        <div class="ml-5">

          <h5 class="text-dacy">{{currentTherapyConcept.title}}</h5>

          <div v-if="currentTherapyConcept.therapyplans && currentTherapyConcept.therapyplans.length > 0">

            <el-collapse v-model="currentTherapyPlanIndex" class="border-top-0">
              <el-collapse-item :name="index" :key="plan.id" v-for="(plan, index) in currentTherapyConcept.therapyplans">
                <template #title>
                  {{(plan.title)}}
                </template>
                {{(plan.description)}}

                <div v-if="plan.therapies && plan.therapies.length > 0">

                  <h6 class="mt-4">Therapieprinzipien</h6>
                  <el-button type="primary" size="mini" icon="el-icon-circle-plus" @click="addTherapies(plan)">Therapie hinzufügen</el-button>

                  <ul>
                  <li :key="plantherapy.id" v-for="plantherapy in plan.therapies">{{(plantherapy.therapy.code)}}</li>
                  </ul>
                </div>

                <div v-if="plan.studyresults && plan.studyresults.length > 0">
                  <h6 class="mt-2">Studienergebnisse</h6>
                  <el-collapse :model-value="0" accordion class="border-top-0 border-bottom-0">
                    <el-collapse-item :name="studyindex" :title="studyResult.study.title" :key="studyResult.id" v-for="(studyResult, studyindex) in plan.studyresults">
                      <h6 v-if="studyResult.pro_con == 1">Endpunkte</h6>
                      <h6 v-if="studyResult.pro_con == 2">Einschränkungen</h6>
                      <study-result-description :description="studyResult.description" :literature="studyResult.study.literature"></study-result-description>
                      <h6 v-if="studyResult.study.literature.length" class="mt-2">Literatur</h6>
                      <p :key="literature.id" v-for="literature in studyResult.study.literature">
                        <span v-if="literature.link_pubmed"><a href="javascript:void(0)" @click="openBrowser(literature.link_pubmed)">{{literature.signature}}</a></span>
                        <span v-else>{{literature.signature}}</span>
                      </p>
                      <h6 class="mt-4" v-if="studyResult.study.ECOG.length">ECOG</h6>
                      <span class="mr-3" :key="ECOG.id" v-for="ECOG in studyResult.study.ECOG">
                        <el-tag :title="ECOG.meta.description">{{ECOG.meta.ecog}}</el-tag>
                      </span>
                    </el-collapse-item>
                  </el-collapse>
                </div>

              </el-collapse-item>
            </el-collapse>

          </div>

        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: "PatientTherapyConcept",
  props: [],
  components: {
  },
  data() {
    return {
      currentTherapyConcept: null,
      currentTherapyConceptLoading: false,
      allDiagnosis: [],
      allTreatmentSituations: [],
      allBiomarker: [],
      currentTherapyPlanIndex: [ 0 ],
      form: {
        diagnosis: null,
        treatmentsituation: null,
        biomarker: 18,
      },
    }
  },
  mounted: async function () {

    await this.getOncologyDiagnosis()
    await this.getBiomarker()
    await this.getTreatmentsituations()
    await this.getTherapyConcepts(this.form)

    this.allDiagnosis = {...this.oncologyDiagnosis}
    this.allTreatmentSituations = {...this.treatmentsituations}
    this.allBiomarker = {...this.biomarker}
  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'fullInfo']),
    ...mapState('therapyconcepts', ['therapyconcepts']),
    ...mapState('diagnosis', ['oncologyDiagnosis']),
    ...mapState('biomarker', ['biomarker']),
    ...mapState('treatmentsituations', ['treatmentsituations']),
    filteredTherapyConcepts: function() {
      if(!this.therapyconcepts) return []
      return this.therapyconcepts
    }
  },
  watch: {
    'form.diagnosis': async function () {
      this.form.treatmentsituation = null
      this.form.biomarker = null
      await this.getTherapyConcepts(this.form)
      this.filterTreatmentSituations()
      this.filterBiomarker()
    },
    'form.treatmentsituation': async function () {
      if(this.form.treatmentsituation !== null) {
        this.form.biomarker = null
        await this.getTherapyConcepts(this.form)
        this.filterBiomarker()
      }
    },
    'form.biomarker':async function () {
      if(this.form.biomarker !== null) {
        await this.getTherapyConcepts(this.form)
      }
    }
  },
  methods: {
    ...mapActions('diagnosis', ['getOncologyDiagnosis']),
    ...mapActions('biomarker', ['getBiomarker']),
    ...mapActions('treatmentsituations', ['getTreatmentsituations']),
    ...mapActions('therapyconcepts', ['getTherapyConcepts']),
    filterTreatmentSituations() {
      let filteredTreatmentSituations = []
      let addedTreatmentSituations = []
      let allTherapyConcepts = JSON.parse(JSON.stringify(this.therapyconcepts))

      for(const concept of allTherapyConcepts) {

        if(concept.treatmentSituation && addedTreatmentSituations.indexOf(concept.treatmentSituation.id) === -1) {
          addedTreatmentSituations.push(concept.treatmentSituation.id)
          filteredTreatmentSituations.push(concept.treatmentSituation)
        }

      }
      this.allTreatmentSituations = filteredTreatmentSituations

    },
    filterBiomarker() {
      let filteredBiomarker = []
      let addedBiomarker = []
      let allTherapyConcepts = JSON.parse(JSON.stringify(this.therapyconcepts))

      for(const concept of allTherapyConcepts) {

        if(concept.biomarker && concept.biomarker.length) {
          for(const biomarker of concept.biomarker) {
            if(addedBiomarker.indexOf(biomarker.id) === -1) {
              addedBiomarker.push(biomarker.id)
              filteredBiomarker.push(biomarker)
            }
          }
        }

      }

      this.allBiomarker = filteredBiomarker
    },
    async onTherapyConceptRowClick(row) {
      this.currentTherapyConcept = row

      if(this.currentTherapyConcept.therapyplans && this.currentTherapyConcept.therapyplans.length > 1) {
        this.currentTherapyPlanIndex = []
      }
      else {
        this.currentTherapyPlanIndex = [0]
      }

      /*this.currentTherapyLoading = true
      const result = await axios.get('/therapies/' + row.id)
      if(result.status === 404) {
        this.$notify.error({
          message: "Therapie wurde nicht gefunden",
          position: 'bottom-left'
        });
      }
      else {
        this.currentTherapy = result.data
      }
      this.currentTherapyLoading = false
       */
    },
    addTherapies(therapyplan) {
      console.log(therapyplan)
      const therapy_ids = therapyplan.therapies.map((t) => t.therapy_id)
      const therapyProtocol_ids = therapyplan.therapies.map((t) => t.therapyProtocols.map((p) => p.id))
      this.$router.push({ path: '/app/patient/' + this.currentPatient.id + '/therapy', query: { referer:'therapyplan', therapies: therapy_ids.join(','), therapyProtocols: therapyProtocol_ids.join(',') } });
    }
  }
}
</script>
<style>
.text-dacy, .el-collapse-item__header {
  color:#cf9236;
}

.el-collapse-item .el-collapse-item .el-collapse-item__header {
  color:#00A;
  font-size:15px;
}

.el-collapse-item__header {
  font-size:16px;
}
</style>
